<template>
  <div class="bus_box">
      <div class="bus_type">
          <img src="@/assets/images/ui/chair.svg" alt="">
          <p> {{item.bus.bus_type == 'sleeping' ? 'Спальный' : 'Сидячий'}}</p>
      </div>
      <div class="box_wrapper">
          <div class="details">
            <div class="details__location">
                <p v-text="item.is_back ? item.hotel_name : item.from_location.name"></p>
                <p>{{$moment(item.check_out_time*1000).format('DD MMM, ddd')}}</p>
                <p>{{$moment(item.check_out_time*1000).format('HH:mm')}}</p>
            </div>
            <div class="details__path">
                <p>В пути: {{formatDate(item.arrival_time - item.check_out_time)}}</p>
                <div></div>
                <p>Номер автобуса: {{item.bus.number}}</p>
            </div>
            <div class="details__location">
                <p v-text="item.is_back ? item.to_location.name : `${item.to_location.name}(${item.hotel_name})`"></p>
                <p>{{$moment(item.arrival_time*1000).format('DD MMM, ddd')}}</p>
                <p>{{$moment(item.arrival_time*1000).format('HH:mm')}}</p>
            </div>
          </div>
          <div v-if="!hideAction" class="actions">
              <p>{{item.price}} KZT</p>
              <p>Цена за всех пассажиров</p>
              <v-btn
                width="160"
                height="48"
                color="primary"
                outlined
                dense
                @click="onClick"
              >
                Выбрать
              </v-btn>
          </div>
      </div>
  </div>
</template>

<script>
import { getTime } from '@/utils/timeMask.js'
export default {
    props: {
        hideAction: Boolean,
        item: Object
    },
    methods: {
        onClick() {
            this.$emit('onClick',this.item)
        },
        formatDate(sec) {
            return getTime(sec)
            // if (Math.floor(sec/86400)) {
            //     return `${Math.floor(sec/86400)} день ${this.$moment().startOf('day').seconds(sec).format('HH')} часа ${this.$moment().startOf('day').seconds(sec).format('mm')} минут`
            // } else {
            //     return `${this.$moment().startOf('day').seconds(sec).format('HH')} часов ${this.$moment().startOf('day').seconds(sec).format('mm')} минут`
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
.bus_box {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
    border-radius: 5px;
    padding-right: 59px;
    padding-left: 55px;
    padding-bottom: 9px;
    margin-top: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    .bus_type {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 117px;
        height: 22px;
        background: #52B1BA;
        border-radius: 5px 2px;
        display: grid;
        grid-template-columns: 10px 1fr;
        grid-column-gap: 6px;
        align-items: center;
        padding-left: 20px;
        p {
            font-size: 12px;
            line-height: 14px;
            color: #E9E9E9;
            margin-bottom: 0;
        }
    }
}
.box_wrapper {
    display: grid;
    grid-template-columns: 1fr 160px;
    align-items: center;
    grid-column-gap: 40px;
    width: 100%;
    min-height: 103px;
}
.details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    &__location {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        p {
            white-space: nowrap;
            &:nth-of-type(1) {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-bottom: 0;
            }
            &:nth-of-type(2) {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin: 6px 0px;
            }
            &:nth-of-type(3) {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #021011;
                margin-bottom: 0;
            }
        }
    }
    &__path {
        width: 100%;
        margin: 0 30px;
        div {
            height: 2px;
            width: 100%;
            background: #52B1BA;
        }
        p {
            text-align: center;
            &:first-of-type {
                font-size: 16px;
                line-height: 19px;
                color: #cdcdcd;
                margin-bottom: 5px;
            }
            &:last-of-type {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-bottom: 0;
                margin-top: 5px;
            }
        }
    }
}
.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
        &:first-of-type {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #FF8413;
            margin-bottom: 0;
        }
        &:last-of-type {
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            margin: 6px 0px;
        }
    }
}
</style>