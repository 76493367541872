const dayLabel = function(d) {
    let label = 'дней'
    if (d%10 == 1 && d != 11) {
        label = 'день'
    } else if (d < 15 && d != 12 && d != 13 && d != 14 && [2,3,4].includes(d%10)) {
        label = 'дня'
    }
    return label
}

const hourLabel = function(d) {
    let label = 'часов'
    if (d%10==1 && d != 11) {
        label = 'час'
    } else if (d < 15 && d != 12 && d != 13 && d != 14 && [2,3,4].includes(d%10)) {
        label = 'часа'
    }
    return label
}

export const getTime = function(seconds) {
    const d = Math.floor(seconds / (3600*24));
    const h = Math.floor(seconds % (3600*24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    return `${d==0?'':d} ${d==0?'':dayLabel(d)}  ${h==0?'':h} ${h==0?'':hourLabel(h)} ${m==0?'':m} ${m==0?'':'мин'}`
}

