<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <select-bus-box v-if="ready" :hideAction='true' :item='item'/>
        <bus-model v-if="ready" ref="busModel" :selectable='true'  :limit='limit()'/>
        <v-btn @click="submitHandler" color="primary" width="160" height="48" class="mt-6">Выбрать</v-btn>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import selectBusBox from '@/components/buses/selectBusBox.vue'
import BusModel from '@/components/buses/busModel.vue'
import {mapMutations} from 'vuex'
import { busesService } from '@/services/buses.service.js'

export default {
    components: {
      selectBusBox,
      BusModel,
      VueBreadcrumbs
    },
    data: () => ({
        hotel: null,
        ready: false,
        item: {}
    }),
    computed: {
        breadCrumbs() {
            let firstQuery = {
                adult: this.$route.query.adult,
                child: this.$route.query.child,
                pensioner: this.$route.query.pensioner,
                from_date: this.$route.query.from_date,
                to_date: this.$route.query.to_date,
                from_location: this.$route.query.from_location,
                to_location: this.$route.query.to_location,
            }
            return [
                {
                    text: 'Бронирование автобусов',
                    to: `/booking/buses/`,
                    query: firstQuery
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/rt/${this.$route.params.id}`,
                    query: { ...firstQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Обратно',
                    to: `/booking/buses/rt/${this.$route.params.id}/search/`,
                    query: this.$route.query
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/rt/${this.$route.params.id}/search/${this.$route.params.second}`,
                },
            ]
        }
    },
    created() {
        this.getBus()
        if (this.$route.query.second_hotel) {
            this.hotel = Number(this.$route.query.second_hotel)
        }
    },
    methods: {
        ...mapMutations({
            setSleepingModel: 'busModel/SET_SLEEPING_MODEL',
            translateFromBack: 'busModel/TRANSFORM_BUS_MODEL_FROM_BACK'
        }),
        limit() {
            return Number(this.$route.query.adult) + Number(this.$route.query.child) + Number(this.$route.query.pensioner)
        },
        async submitHandler() {
            try {
                this.$loading(true)
                const seat_places = this.$refs.busModel.getClickedSeats()
                const data = {
                    id: this.$route.params.second,
                    seat_places: seat_places.map(el => el.id),
                    is_rt: true, 
                    is_back: true, 
                }
                if(this.limit() != seat_places.length) {
                    let str = ''
                    let limit = this.limit()
                    if(limit == 1) str = '1 место'
                    else if(limit > 1 && limit < 5) str = `${limit} места`
                    else str = `${limit} мест`
                    this.$store.commit("ui/SET_NOTIFICATION", {
                        show: true,
                        message: `Нужно выбрать ${str}!`,
                        color: "error",
                    });
                    return;
                }
                await busesService.bookBusSeatPlaces(data)
                let query = {
                    ...this.$route.query,
                    second_hotel: this.hotel,
                    second_seats: seat_places.map(el => el.id).join(',')
                }
                this.$router.push({
                    path: `/booking/buses/rt/${this.$route.params.id}/search/${this.$route.params.second}/booking`,
                    query
                })
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        async getBus() {
            try {
                this.$loading(true)
                this.ready = false
                const res = await busesService.getSingleActiveBus({id: this.$route.params.second, hotel: Number(this.$route.query.hotel), is_back: true, is_rt: true})
                this.item = res
                this.item.bus.bus_type = res.bus.bus_type
                this.translateFromBack(res.bus)
                this.ready = true
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>